<template>
  <div id="footer">
    <div class="footer-inner mx-auto py-10 px-10">
      <div v-if="content" class="footer-content text-center -tracking-0.16 bg-creme_light mb-5" v-html="content">
      </div>
      <div class="footer-buttons" v-if="buttons.length > 0" >
        <Buttons v-for="(button, key) of buttons" :key="key" :buttons="button" :type="null" :href="button.href" :color="button.color" :icon="!!button.icon" :icon_type="button.icon" :icon_weight="button.icon_weight" :content="button.content" />
      </div>
    </div>
    <FooterNav />
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import eventService from "@/services/eventService";
import qs from "qs";
import ApiService from "@/services/apiService";
import filterService from "@/services/filterService";
import Buttons from "@/components/Buttons.vue";

const FooterNav = defineAsyncComponent(() => import('@/partials/FooterNav'));

export default {
  name: "Footer",
  data() {
    return {
      content: '',
      buttons: [],
      queryOptions: {
        sort: [],
        filters: {},
        locale: [],
        populate: '*'
      }
    }
  },
  components: {
    Buttons,
    FooterNav
  },
  created() {
    this.queryOptions.locale = [this.$root.$i18n.locale];
    this.fetchContent();
  },
  mounted() {
    eventService.$on('lang-switch', (lang) => {
      this.queryOptions.locale = [lang];
      this.fetchContent();
    });
  },
  methods: {
    fetchContent() {
      let query = qs.stringify(this.queryOptions);
      ApiService.get(`footer-content?${query}`).then(res => {
        this.content = filterService.textCompile(res.data.data.attributes.Text, {emoji: true, openLinksInNewWindow: false});
        this.buttons = res.data.data.attributes.buttons;
        console.log(this.buttons)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .footer-inner {

    max-width: var(--section-width);
  }
</style>